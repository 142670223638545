import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  tooltip: {
    fontSize: 13,
    marginBottom: '5px'
  }
})

const CustomTooltip = props => {
  const classes = useStyles()

  return <Tooltip arrow classes={classes} {...props} />
}

export default props => (
  <CustomTooltip
    title={props.title}
    placement={props.placement ? props.placement : 'top-start'}
    arrow
    open={props.open}
    enterTouchDelay={0}
  >
    {props.children}
  </CustomTooltip>
)
